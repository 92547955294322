(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory)
  } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'))
  } else {
    // Browser globals
    factory({}, root.echarts)
  }
}(this, (exports, echarts) => {
  const log = function (msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg)
    }
  }
  if (!echarts) {
    log('ECharts is not Loaded')

    return
  }
  echarts.registerTheme('chalk', {
    color: [
      '#e95455',
      '#28c76f',
      '#836af9',
    ],
    backgroundColor: '#312d4b',
    textStyle: {},
    title: {
      textStyle: {
        color: '#cfcce6',
      },
      subtextStyle: {
        color: '#f1f1f4',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '0',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: '0',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: '0',
        barBorderColor: 'rgba(204,204,204,0.04)',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#fc97af',
        color0: 'transparent',
        borderColor: '#fc97af',
        borderColor0: '#87f7cf',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: '0',
        borderColor: 'rgba(204,204,204,0.04)',
      },
      lineStyle: {
        width: '1',
        color: '#ffffff',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
      color: [
        '#e95455',
        '#28c76f',
        '#836af9',
      ],
      label: {
        color: '#fffcfc',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
      },
      label: {
        color: '#893448',
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
        label: {
          color: 'rgb(137,52,72)',
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
      },
      label: {
        color: '#893448',
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
        label: {
          color: 'rgb(137,52,72)',
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#f1f1f4',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#f1f1f4',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [
            '#e6e6e6',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#f1f1f4',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#f1f1f4',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [
            '#e6e6e6',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#f1f1f4',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#f1f1f4',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [
            '#e6e6e6',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#f1f1f4',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#f1f1f4',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [
            '#e6e6e6',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: 'rgba(251,251,251,0.96)',
      },
      emphasis: {
        iconStyle: {
          borderColor: 'rgba(211,183,239,0.81)',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#f1f1f4',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
        textStyle: {
          color: 'rgba(250,250,250,0.05)',
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#87f7cf',
        width: 1,
      },
      itemStyle: {
        color: '#87f7cf',
        borderWidth: 1,
      },
      controlStyle: {
        color: '#87f7cf',
        borderColor: '#87f7cf',
        borderWidth: 0.5,
      },
      checkpointStyle: {
        color: '#fc97af',
        borderColor: '#fc97af',
      },
      label: {
        color: '#87f7cf',
      },
      emphasis: {
        itemStyle: {
          color: '#f7f494',
        },
        controlStyle: {
          color: '#87f7cf',
          borderColor: '#87f7cf',
          borderWidth: 0.5,
        },
        label: {
          color: '#87f7cf',
        },
      },
    },
    visualMap: {
      color: [
        '#fc97af',
        '#87f7cf',
      ],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(114,204,255,1)',
      fillerColor: 'rgba(114,204,255,0.2)',
      handleColor: '#72ccff',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#fffcfc',
      },
      emphasis: {
        label: {
          color: '#fffcfc',
        },
      },
    },
  })
}))
