import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMyChannel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/myToPaymentListWeb`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/checkAllBill`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    download(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/pdf/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPdf(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/bill/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/payeeByBillId/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirm(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/cpCheckPayee/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
