<template>
  <v-card
    ref="card"
    style="padding: 40px"
  >
    <v-row class="d-flex align-center mb-3">
      <div>
        <v-card-title>{{ bill.type==='YT'?'Youtube':'Facebook' }} 收益结算单</v-card-title>
        <v-card-subtitle>本结算单由网络系统自动加密生成，数据来自{{ bill.type==='YT'?'Youtube':'Facebook' }}官方后台</v-card-subtitle>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="download"
      >
        下载
      </v-btn>
    </v-row>
    <h1
      class="ml-3 mb-2"
    >
      {{ bill.channelName }}结算单
    </h1>
    <v-container>
      <v-row class="mb-4 ml-3">
        <v-col cols="6">
          <v-row>主页名称：{{ bill.channelName }}</v-row>
          <v-row>账单日期：{{ bill.month }}</v-row>
          <v-row>货币：人民币</v-row>
        </v-col>
        <v-col cols="6">
          <v-row>收款人：{{ bill.bankAccountName }}</v-row>
          <v-row>开户行：{{ bill.openingBank }}</v-row>
          <v-row>支行：{{ bill.bankBranchName }}</v-row>
          <v-row>收款账号：{{ bill.bankAccount }}</v-row>
          <v-row>身份证/社会信用代码：{{ bill.idCard }}</v-row>
        </v-col>
      </v-row>
      <div
        id="main"
        ref="chart"
      ></div>
      <v-row
        class="mt-5"
        style="border: 1px solid"
      >
        <v-col md="6" cols="12" style="border-right: 1px solid">
          <v-row class="d-flex align-center">
            <v-col cols="8" class="d-flex align-content-end">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/1播放量.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">播放量</span>
                  <small>*以{{ bill.type==='YT'?'Youtube':'Facebook' }}平台导出数据为准</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              {{ bill.views?bill.views.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/11美国收益.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">美国收益（$）</span>
                  <small>*以{{ bill.type==='YT'?'Youtube':'Facebook' }}平台导出数据为准</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.usEstimatedRevenue?bill.usEstimatedRevenue.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/4可分配收益.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">可分配收益（$）</span>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.distributableIncome?bill.distributableIncome.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/6客户收益.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">客户收益（应发）</span>
                  <small>应发$</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.distributableIncome?(bill.distributableIncome*bill.splitRatio/100).toFixed(2).toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/8实发.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">客户收益（实发）</span>
                  <small>实发$</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.incomeTotal?bill.incomeTotal.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12">
          <v-row class="d-flex align-center">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/2频道收益.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">频道收益（$）</span>
                  <small>*以{{ bill.type==='YT'?'Youtube':'Facebook' }}平台导出数据为准</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.estimatedRevenue?bill.estimatedRevenue.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/3联邦税费.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">联邦税费（$）</span>
                  <small>*{{ bill.type==='YT'?'Youtube':'Facebook' }}平台代扣代缴</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <span v-show="bill.usEstimatedRevenue">${{ (bill.usEstimatedRevenue*bill.usTax/100).toFixed(2) }}({{ bill.usTax }}%)</span>
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/5分成比例.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">分成比例</span>
                  <small>客户分成比例%</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              {{ bill.splitRatio }}%
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/7手续费.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">付款手续费（$）</span>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              $ {{ bill.taxDollar }}({{ bill.taxPoint }}%)
            </v-col>
          </v-row>
          <v-row class="row-Sty">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/9美元汇率.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">美元汇率</span>
                  <small>*参考次月收款日左右的汇率</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              {{ bill.rate?bill.rate:'-' }}
            </v-col>
          </v-row>
        </v-col>
        <v-col style="border-top: 1px solid" md="12">
          <v-row class="d-flex align-center">
            <v-col cols="8">
              <div class="inCol-Sty">
                <v-avatar
                  rounded
                  size="50"
                >
                  <v-img
                    src="../../assets/images/billIcon/10实发.png"
                  ></v-img>
                </v-avatar>
                <div
                  class="d-flex flex-column ms-3"
                >
                  <span class="d-block font-weight-semibold text--primary text-truncate">客户收益（实发）</span>
                  <small>实发￥</small>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              ￥ {{ bill.incomeRmb?bill.incomeRmb.toLocaleString('en-US'):'-' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="mt-5">
        <small>*提示：由于美元汇率经常起伏波动，一般参考次月收款日左右汇率。频道收益以{{ bill.type==='YT'?'Youtube':'Facebook' }}平台导出数据报告为准。</small>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import * as echarts from 'echarts'
import '../../@core/utils/chalk'
import WaterMark from 'l-watermark'
import { onMounted, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import store from '@/store'
import storeModule from '@/views/billSettlement/beSettle/queueStoreModule'

export default {
  setup() {
    const WORK_STORE_MODULE_NAME = 'beSettle-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // 创建水印
    const demoWaterMark = WaterMark.page({})
    const card = ref(null)
    const chart = ref(null)
    const billData = ref({})
    const bill = ref({})
    const loading = ref(false)
    const { route } = useRouter()
    const option = ref({
      title: {
        text: '数据详情',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
      },
      toolbox: {
        show: false,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ['次数', '预估收入'],
      },
      xAxis: [
        {
          type: 'category',
          data: ['1', '2', '3', '4', '5', '6', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7', '7'],
          axisPointer: {
            type: 'shadow',
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#836af9',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '次数',

          // min: 0,
          // max: 150,
          // interval: 30,
          axisLabel: {
            formatter(value) {
              if (value >= 10000 && value <= 10000 * 100) {
                return `${value / 10000}万`
              }
              if (value > 10000 * 100 && value <= 10000 * 1000) {
                return `${value / 10000}百万`
              }
              if (value > 10000 * 1000 && value <= 10000 * 10000) {
                return `${value / 10000}千万`
              }
              if (value > 10000 * 10000) {
                return `${value / 10000}亿`
              }

              return value
            },
          },
          splitLine: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e95455',
            },
          },
        },
        {
          type: 'value',
          name: '预估收入',

          // min: 0,
          // max: 140,
          // interval: 20,
          axisLabel: {
            formatter: '$ {value}',
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#28c76f',
            },
          },
        },
      ],
      series: [
        {
          name: '次数',
          type: 'bar',
          tooltip: {
            valueFormatter(value) {
              return `${value} 次`
            },
          },
          data: [
            2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
          ],
        },
        {
          name: '预估收入',
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter(value) {
              return `$ ${value}`
            },
          },
          data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
        },
      ],
    })

    onMounted(() => {
      WaterMark.page({
        target: card.value.$el,
        text: '绝密',
        cSpace: 60,
        vSpace: 60,
        color: 'rgba(0, 0, 0, 0.15)',
        fontSize: 30,
      })

      const myChart = echarts.init(chart.value, 'chalk')
      myChart.setOption(option.value)

      const showData = () => {
        myChart.showLoading()
        store.dispatch('beSettle-list/fetchPdf', route.value.query.id)
          .then(response => {
            if (response.data.status === 200) {
              billData.value = response.data.data
              bill.value = response.data.data.bill

              // const Max = arr => {
              //   let inteval
              //   let max
              //   let l = 0
              //   let i = Math.max(...arr)
              //   while (i >= 1) {
              //     i /= 10
              //     l += 1
              //   }
              //   if (l <= 2) {
              //     inteval = Math.ceil(Math.ceil(Math.max(...arr)) / 6)
              //     max = Math.ceil(Math.max(...arr))
              //   } else if (l >= 3) {
              //     inteval = Math.ceil(Math.ceil(Math.max(...arr) / (10 * (l - 2))) / 6) * (10 * (l - 2))
              //     max = Math.round(Math.max(...arr))
              //   }
              //
              //   return { max, inteval }
              // }
              const arr = []
              for (let i = 1; i <= billData.value.revenue.length; i += 1) {
                arr.push(i.toString())
              }

              // option.value.yAxis[0].max = Max(billData.value.view).max
              // option.value.yAxis[0].interval = Max(billData.value.view).inteval
              // option.value.yAxis[1].max = Max(billData.value.revenue).max
              // option.value.yAxis[1].interval = Max(billData.value.revenue).inteval
              option.value.xAxis[0].data = arr
              option.value.series[0].data = billData.value.view
              option.value.series[1].data = billData.value.revenue
              myChart.setOption(option.value)
              myChart.hideLoading()
            }
          })
          .catch(error => {
            modalFail(error)
            if (error.response.status === 404) {
              console.log(error)
            }
          })
      }
      showData()
    })

    const download = () => {
      loading.value = true
      store.dispatch('beSettle-list/download', route.value.query.id).then(response => {
        if (response.data.status === 200) {
          loading.value = false
          window.open(response.data.data.pdfUrl)
          toastSuccess('下载成功!')
        }
      }).catch(error => {
        modalFail(error.response.data.message)
        loading.value = false
      })
    }

    // 移除水印
    demoWaterMark.remove()

    return {
      card,
      chart,
      bill,
      billData,
      loading,
      download,
    }
  },
}
</script>

<style scoped>
#main{
  width: 100%;
  height: 500px;
}
.row-Sty{
  display: flex;
  align-items: center;
  border-top: 1px solid;
}
.inCol-Sty{
  display: flex;
  align-items: center;
  margin: 9px 0 8px 9px;

}
</style>
